import { Controller } from '@hotwired/stimulus'
import { locale, getLocalization } from 'libs/localization'

export default class extends Controller {
  static targets = ['current']

  async connect() {
    const { backendMode } = this.element.dataset

    const liveChatComponent = backendMode === 'default' ? 'LiveChat' : 'PubnubLiveChat'

    this.render(
      ...await Promise.all([
        getLocalization(),
        (await import('vue')).default,
        (await import('vue-i18n')).default,
        (await import('storefront/store')).default,
        (await import('vue-chat-scroll')).default,
        (await import('storefront/views/Program/components/LiveChat'))[liveChatComponent],
      ]),
    )
  }

  render(messages, Vue, VueI18n, store, VueChatScroll, LiveChat) {
    const {
      contentId, avatarUrl, userName, userId,
      userUuid, isDonationsEnabled, isAdmin,
      backendMode, messagesChannelId,
      signalsChannelId, userAuthKey, publishKey,
      subscribeKey, sseLink
    } = this.element.dataset

    let componentProps = {
      useToggle: false,
      contentId: Number(contentId),
      userId: Number(userId),
      avatarUrl,
      userName,
      isAdmin: isAdmin === 'true',
      isDonationsEnabled: isDonationsEnabled === 'true',
      backendMode,
    }

    if (backendMode === 'default') {
      componentProps.sseLink = sseLink
    } else {
      componentProps = {
        messagesChannelId,
        signalsChannelId,
        userAuthKey,
        publishKey,
        subscribeKey,
        userUuid,
        ...componentProps
      }
    }
    Vue.use(VueChatScroll)
    Vue.use(VueI18n)

    const i18n = new VueI18n({ locale, messages })

    this.widget = new Vue({
      store,
      i18n,
      render: h => h(LiveChat, {
        props: componentProps,
        on: {
          donation: analytics => {
            analytics.track({
              event: 'donation_form_opened',
              eventCategory: 'donation',
              value: 1,
              details: {
                minutes: Math.round(this.program.playerCore.state.time / 60),
              },
            })
          },
        },
      }),
    })

    this.widget.$mount(this.currentTarget)
  }

  disconnect() {
    this.widget?.$destroy()
  }

  get program() {
    const element = this.element.closest('[data-controller=program]')
    return this.application.getControllerForElementAndIdentifier(element, 'program')
  }
}
